import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Session from "../images/30minsession.svg"
import PowerScores from "../images/powerscores.svg"
import BonusInsights from "../images/bonusinsights.svg"
import Experience from "../images/experience.svg"
import SessionDesktop from "../images/demo/session-desktop.json"
import SessionMobile from "../images/demo/session-mobile.json"
import { FaCheckCircle as CheckIcon } from "react-icons/fa"
import FormsService from "../service/formsservice"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./session.scss"

export default class Strategy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      emailError: false,

      formData: {
        emailUpdatesAgreed: false,
      },
      lottieComp: null,
    }
  }

  validateEmail = (email) => {
    if (/^[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      this.setState({ emailError: false })
      return true
    } else {
      this.setState({ emailError: true })
      return false
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleChangeEmailUpdates = (e) => {
    console.log(e.target.checked)
    let checked = e.target.checked
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          emailUpdatesAgreed: checked,
        },
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.validateEmail(this.state.formData.email)) {
      const form = e.target
      this.setState({ submitting: true })

      let data = JSON.stringify(this.state.formData)

      FormsService.strategyForm(data)
        .then((res) => {
          if (res) {
            this.setState({ submitted: true })
          }
        })
        .catch((error) => console.log("Error: ", error))
        .finally(() => {
          this.setState({ submitting: false })
        })
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      import("react-lottie").then((module) =>
        this.setState({ lottieComp: module.default })
      )
    }

    let sessionVideo = document.getElementById("session-video")
    if (!sessionVideo) return
    sessionVideo.play()
  }
  getResponsiveHeight = () => {
    let responsiveHeight = "950px"
    if (window.matchMedia("(max-width: 680px)").matches) {
      responsiveHeight = "480px"
    } else {
      responsiveHeight = "950px"
    }
    return responsiveHeight
  }

  render() {
    const { data } = this.props
    const { submitted, submitting } = this.state
    let animatedData = SessionDesktop
    if (
      typeof window !== `undefined` &&
      window.matchMedia("(max-width: 680px)").matches
    ) {
      animatedData = SessionMobile
    }
    const defaultOptions = {
      loop: true,
      autoplay: true,
      renderer: "canvas",

      animationData: animatedData,
      rendererSettings: {
        clearCanvas: true,
        preserveAspectRatio: "xMidYMid slice",
      },
    }
    const { lottieComp: LottieComp } = this.state

    return (
      <Layout>
        <SEO title="Strategy" />

        <div className="outer-wrapper">
          <div className="leftContainerStrategy">
            <div className="leftContainerSpecsWrapper">
              <div className="leftContainerSpecItems">
                <img src={Session} />
                <div>
                  <strong> Just 30 mins session </strong>
                </div>
                <div>Get FREE Company ROI Report</div>
              </div>
              <div className="leftContainerSpecItems">
                <img src={PowerScores} />
                <div>
                  <strong>Power scores</strong>
                </div>
                <div>Real value of investment metrics</div>
              </div>
              <div className="leftContainerSpecItems">
                <img src={BonusInsights} />
                <div>
                  <strong>Bonus Insights</strong>
                </div>
                <div>Get ‘flourishing’ employees faster</div>
              </div>
              <div className="leftContainerSpecItems">
                <img src={Experience} />
                <div>
                  <strong> Experience SHAPE</strong>
                </div>
                <div>See the service firsthand</div>
              </div>
            </div>
            <div className="leftContainerVideoWrapper">
              {LottieComp && (
                <LottieComp
                  options={defaultOptions}
                  height={this.getResponsiveHeight()}
                  width={950}
                  isStopped={false}
                  isPaused={false}
                />
              )}
            </div>
          </div>
          <div className="customContainerStrategy">
            <h1>Take the FREE test. Get your company score </h1>
            <p>There's no obligation to use our services.</p>
            <form
              name="strategysession"
              action="/thanks/"
              onSubmit={this.handleSubmit}
              disabled={submitted}
              id="strategy-form"
            >
              <Row>
                <Col sm={12}>
                  <div className="form-group" className="input-parent">
                    <label>Your full name *</label>

                    <input
                      type="text"
                      required
                      id="strategy-input-fullname"
                      name="fullName"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="form-group" className="input-parent">
                    <label>Your company name *</label>

                    <input
                      type="text"
                      required
                      id="strategy-input-companyName"
                      name="companyName"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group" className="input-parent">
                    <label>Work email address *</label>

                    <input
                      type="text"
                      required
                      id="strategy-input-email"
                      name="email"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                    {this.state.emailError && (
                      <div className="invalidEmail">Email is invalid</div>
                    )}
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                {submitted && (
                  <div className="submittedSuccess">
                    Thank you. We’ll be in touch shortly.
                  </div>
                )}
                <button
                  type="submit"
                  id="strategy-form-submit-button"
                  disabled={submitted || submitting}
                  className="text-white"
                >
                  {(submitted && "Submitted") ||
                    (submitting && "Submitting...") ||
                    "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allCountryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allPositionJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allIndustryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allHowFindJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allCompanySizeJson {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`
